import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import "./SelectionPanel.css";
import {
  getAppState,
  updateAppState,
  updateSearchQueryState,
  getSearchQueryState
} from "../AppState";
import DataFetch from "./DataFetch";
import { ACTIONS } from "../Constants";


const SelectionPanel = ({ searchTerm }) => {
  const [suggestions, setSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState(searchTerm || "");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Handle initial app state updates if search term exists
  useEffect(() => {
    const searchQueryState = getSearchQueryState();
    if (searchQueryState.search_term != null) {
      const appState = getAppState();
      appState.last_action = ACTIONS.LOOKUP;
      updateAppState(appState);
    }
  }, []);

  // Handle screen resize events
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Get all available suggestions from app state
  const getAllSuggestions = () => {
    const appState = getAppState();
    return appState.search_suggestions || []; // Always show all suggestions
  };

  const handleFocus = () => {
    setSuggestions(getAllSuggestions()); // ✅ Show all suggestions
    setShowSuggestions(true);
  };

  const handleInputChange = (e) => {
    const input = e.target.value;
    setInputValue(input);

    if (input.trim()) {
      setSuggestions(getAllSuggestions().filter((suggestion) =>
        suggestion.toLowerCase().includes(input.toLowerCase())
      ));
    } else {
      setSuggestions(getAllSuggestions()); // ✅ Show all suggestions even when empty
    }

    setShowSuggestions(true);
  };

  const handleSuggestionClick = (suggestion) => {
    setInputValue(suggestion);
    setShowSuggestions(false);

    const searchQueryState = getSearchQueryState();
    if (searchQueryState.search_term !== suggestion) {
      searchQueryState.search_term = suggestion;
      updateSearchQueryState(searchQueryState);
    }

    const appState = getAppState();
    appState.last_action = ACTIONS.LOOKUP;
    updateAppState(appState);
  };

  const handleClearInput = () => {
    setInputValue("");
    setSuggestions(getAllSuggestions()); // ✅ Show all suggestions when cleared
    setShowSuggestions(true);
  };

  const handleBlur = () => {
    setTimeout(() => setShowSuggestions(false), 150); // Delay to allow click events on suggestions
  };

  return (
    <div className="selection-panel">
      <DataFetch />
      <div className="search-container">
        <input
          type="text"
          className="search-input"
          placeholder="Search style, drivetrain, make, or model..."
          value={inputValue}
          onFocus={handleFocus} // ✅ Ensures all suggestions are shown on focus
          onChange={handleInputChange}
          onBlur={handleBlur}
        />
        <div className="icon-wrapper">
          {inputValue ? (
            <ClearIcon
              onClick={handleClearInput}
              className="icon-grey"
              style={{ cursor: "pointer" }}
            />
          ) : (
            <SearchIcon className="icon-grey" />
          )}
        </div>
        {suggestions.length > 0 && showSuggestions && !isMobile && (
          <ul className="suggestions-list">
            {suggestions.map((suggestion, index) => (
              <li
                key={index}
                onMouseDown={() => handleSuggestionClick(suggestion)}
              >
                {suggestion}
              </li>
            ))}
          </ul>
        )}
      </div>
      {isMobile && showSuggestions && (
      <div className="mobile-bottom-sheet">
        <div className="bottom-sheet-header">
          <span>Suggestions</span>
          <button onClick={() => setShowSuggestions(false)}>Close</button>
        </div>
        <div className="bottom-sheet-content">
          {suggestions.map((suggestion, index) => (
            <div
              key={index}
              className="bottom-sheet-item"
              onClick={() => handleSuggestionClick(suggestion)}
            >
              {suggestion}
            </div>
          ))}
        </div>
      </div>
    )}
    </div>
  );
};

export default SelectionPanel;
