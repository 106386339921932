import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { endpoints } from "../Config";
import { MODE } from "../Constants";
import { getAppState, updateAppState, getSimilarityQueryState, getSearchQueryState, updateSearchQueryState} from "../AppState";
import ProductGrid from "./ProductGrid"; // Use the existing ProductGrid for layout
import { convertResults } from "./Util";
import { FaHome } from "react-icons/fa";
import './SimilarProducts.css';

const SimilarProducts = ({productId}) => {
  const navigate = useNavigate();

  const [similarProducts, setSimilarProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch similar cars from the server
    const fetchSimilarCars = async () => {
      try {
        const appState = getAppState();
        const similarityQueryState = getSimilarityQueryState();
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({candidateKey: similarityQueryState.similarity_key, cached: Object.keys(appState.details)})
        };

        const response = await fetch(endpoints.similar, requestOptions);
        const res = await response.json();
        appState.details = { ...appState.details, ...res.diff_results };
        const results = [];
        for(const key of res.result_keys){
          results.push(convertResults(key, appState.details[key].specs, appState.details[key].scores));
        }
        setSimilarProducts(results);
        updateAppState(appState);
      } catch (error) {
        console.error("Error fetching similar cars:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSimilarCars();
  }, [productId]);

  return (
    <div>
      {/* Home Button with Icon */}
      <button 
      onClick={() => {

          const searchQueryState = getSearchQueryState();
          if(searchQueryState.search_term === null){
            const parts = productId.split("$");
            searchQueryState.search_term = `${parts[0]} ${parts[1]}`;
            updateSearchQueryState(searchQueryState);
          }
            navigate("/");
          }
        } 
      className="home-btn"
      >
        <FaHome className="home-icon" /> {/* Home icon */}
        Home
      </button>
      {loading ? (
        <p>Loading similar cars...</p>
      ) : (
        <ProductGrid products={similarProducts} mode= {MODE.SIMILARITY} />
      )}
    </div>
  );
};

export default SimilarProducts;
