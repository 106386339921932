import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import SpecCard from "./SpecCard"; // Import the SpecCard component
import "./ProductCard.css";
import "react-circular-progressbar/dist/styles.css";
import { getSimilarityQueryState, updateSimilarityQueryState } from "../AppState";

const ProductCard = ({ product, productNumber, totalProducts }) => {
  const location = useLocation();
  const { attributes, scores } = product;
  const navigate = useNavigate();

  // State for expanding/collapsing the card itself (not just the specs panel)
  const [isExpanded, setIsExpanded] = useState(false);

  // State for the "Link copied" message
  // Toggle the expanded state of the card
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  // Normalization logic for percentage values
  const normalizeMetric = (value, max = 100) => (value / max) * 100;

  // Function to get the color based on the value
  const getRangeColor = (value) => {
    if (value <= 3) return "#d87f7f"; // Dark pastel red for Low
    if (value <= 7) return "#f2d06e"; // Dark pastel yellow for Medium
    return "#7fa88f"; // Dark pastel green for High
  };

  // Calculate Monthly Payment (if needed)
  const calculateMonthlyPayment = (loanAmount, apr, term) => {
    if (!loanAmount || !apr || !term) return "N/A";
    const monthlyInterestRate = apr / 100 / 12; // Convert APR to monthly interest rate
    const totalPayments = term;
    const monthlyPayment = loanAmount * (monthlyInterestRate * Math.pow(1 + monthlyInterestRate, totalPayments)) / (Math.pow(1 + monthlyInterestRate, totalPayments) - 1);
    return `$${Math.round(monthlyPayment)}`; // Round to the nearest dollar
  };

  const handleSimilarCars = () => {
    const similarityQueryState = getSimilarityQueryState();
    similarityQueryState.similarity_key = product.key;
    updateSimilarityQueryState(similarityQueryState);
    navigate(`/similar-cars?key=${product.key}`);
  };

  // Finance details
  const financeAmount = calculateMonthlyPayment(product.attributes.price, product.attributes.financeAPR, product.attributes.financeTerm);

  const gaugeData = [
    { 
      name: "Range", 
      value: normalizeMetric(scores?.range * 10), 
      text: `${Math.round(attributes?.range)}`,
      fill: getRangeColor(scores?.range), 
      unit: "miles" 
    },
    { 
      name: "MPGe", 
      value: normalizeMetric(scores?.mpge * 10),  
      text: `${Math.round(attributes?.combinedMpge)}`,
      fill: getRangeColor(scores?.mpge), 
      unit: "" 
    },
    { 
      name: "F.Charging", 
      value: normalizeMetric(scores?.fastCharge * 10), 
      text: `${Math.round(attributes?.dcChargeSpeed)}`,
      fill: getRangeColor(scores?.fastCharge), 
      unit: "miles/hr" 
    },
    { 
      name: "HP", 
      value: normalizeMetric(scores?.hp * 10), 
      text: `${Math.round(attributes?.horsepower)}`,
      fill: getRangeColor(scores?.hp), 
      unit: "" 
    }
  ];

  // Format price as currency
  const formattedPrice = attributes.price && !isNaN(attributes.price) ? `$${Number(attributes.price).toLocaleString()}` : "$0";
  const title = `${attributes.make} ${attributes.model}`;


  return (
    <div className={`card-container ${isExpanded ? "expanded" : ""}`}>
       {/* Product Number and Copy Page URL Section */}
       <div className="product-number-band">
        <span>{productNumber}/{totalProducts}</span>
      </div>
      <div className="header">
        <h2 className="title">{title}</h2>
        <div className="subtitle-price-wrapper">
          <h4 className="subtitle">{attributes.trim}</h4>
          <h4 className="price">{formattedPrice}</h4>
        </div>
      </div>
      <div className="image-container">
        <img src={attributes.image} alt={title} className="image" />
      </div>
      <div className="info-container">
        <p className="info-text dimensions">
          {`${attributes?.length}"L × ${attributes?.width}"W × ${attributes?.height}"H`}
        </p>
        
        {/* Additional Info Section */}
        <div className="additional-info">
          <p className="info-text seating">{attributes?.seating} seats</p>
          <p className="info-text style">{attributes?.style}</p>
          <p className="info-text drive-train">{attributes?.driveTrain}</p>
        </div>
      {/* Lease and Finance Offers Section */}
      <div className="offers-container">
        {/* Lease Offer */}
        <div className="offer-details">
          <h4>Lease Offer <sup>†</sup></h4>
          <span>
            {attributes?.leaseAmount !== null && attributes?.leaseAmount !== undefined && attributes?.leaseAmount !== "" 
              ?(
                <a 
                  href={attributes.leaseOfferLink || "#"} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className={`offer-amount-link ${attributes.leaseOfferLink ? "" : "disabled-link"}`}
                >
                  ${new Intl.NumberFormat("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(attributes.leaseAmount)}/m
                </a>
              ) 
              : "N/A"}{" "}
            {attributes?.leaseTerm !== null && attributes?.leaseTerm !== undefined && attributes?.leaseTerm !== "" 
              ? `${attributes.leaseTerm} months` 
              : ""}
          </span>
          {attributes?.leaseDueAtSigning !== null && attributes?.leaseDueAtSigning !== undefined && attributes?.leaseDueAtSigning !== "" && (
            <div>
              Due at signing: 
              <strong> ${new Intl.NumberFormat("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(attributes.leaseDueAtSigning)}</strong>
            </div>
          )}
        </div>

        {/* Finance Offer */}
        <div className="offer-details">
          <h4>Finance Offer <sup>†</sup></h4>
          <span>
            {attributes?.financeAPR !== null && attributes?.financeAPR !== undefined && attributes?.financeAPR !== "" 
              ? (
                <a href={attributes.financeOfferLink || "#"} target="_blank" rel="noopener noreferrer" className={`offer-amount-link ${attributes.financeOfferLink ? "" : "disabled-link"}`}>
                  {attributes.financeAPR}% APR
                </a>
              ) 
              : "N/A"}{" "}
            {attributes?.financeTerm !== null && attributes?.financeTerm !== undefined && attributes?.financeTerm !== "" 
              ? `${attributes.financeTerm} months` 
              : ""}
          </span>
        </div>
      </div>
      </div>
      <div className="gauge-container">
        {gaugeData.map((data, index) => (
          <div key={index} className="gauge">
            <CircularProgressbar
              value={data.value}
              text={data.text}
              styles={buildStyles({
                pathColor: data.fill,
                trailColor: "#e0e0e0",
                textColor: "steelblue",
                textSize: "32px",
                textStyle: {
                  fontFamily: "'Roboto', sans-serif",  // Change the font family here
                  fontWeight: "bold", // Set font weight to bolder using textStyle
                },
              })}
              className="custom-progressbar"
            />
            <p className="gauge-label">{data.name}</p>
            <p className="gauge-unit">{data.unit}</p>
          </div>
        ))}
      </div>

      {/* Render SpecCard if specs are expanded */}
      {isExpanded && (
        <div className="specs-panel">
          <SpecCard
            specs={attributes} // Pass the entire attributes object
          />
        </div>
      )}

      {/* Buttons */}
      <div className="button-container">
        <button className="expand-collapse-btn" onClick={toggleExpand}>
          {isExpanded ? "Hide Specs" : "More Specs..."}
        </button>
        {/* Conditionally render Similar Cars button */}
        {location.pathname !== "/similar-cars" && (
          <button className="similar-cars-btn" onClick={handleSimilarCars}>
            Similar Cars...
          </button>
        )}
      </div>
    </div>
  );
};

export default ProductCard;
