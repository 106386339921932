import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { endpoints } from "./Config";
import "./App.css";
import SelectionPanel from "./components/SelectionPanel";
import { ViewerPanel } from "./components/ViewerPanel";
import SimilarProducts from "./components/SimilarProducts";
import { getAppState, updateAppState, getSearchQueryState, updateSearchQueryState, getSimilarityQueryState, updateSimilarityQueryState } from "./AppState";

function App() {
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  // Extract parameters from URL
  const urlParams = new URLSearchParams(location.search);
  console.log(urlParams);
  const paramSearchTerm = urlParams.get("search_term");
  const paramSimilarityKey = urlParams.get("key");
  const paramSortBy = urlParams.get("sort_by");
  const paramOrderBy = urlParams.get("order_by");
  const paramMaxPrice = parseFloat(urlParams.get("max_price") || 0);
  // Get current AppState
  const appState = getAppState();

  // Validate search_term and key
  const isValidSearchTerm =
    Array.isArray(appState.search_suggestions) &&
    appState.search_suggestions.includes(paramSearchTerm);

  const searchQueryState = getSearchQueryState();
  console.log(searchQueryState);
  if(isValidSearchTerm){
    searchQueryState.search_term = paramSearchTerm;
    searchQueryState.sort_by = paramSortBy || searchQueryState.sort_by;
    searchQueryState.order_by = paramOrderBy || searchQueryState.order_by;
    searchQueryState.max_price = paramMaxPrice || searchQueryState.max_price;
    updateSearchQueryState(searchQueryState);
  }

  const isValidSimilarityKey =
    Array.isArray(appState.candidate_keys) &&
    appState.candidate_keys.includes(paramSimilarityKey);

   const similarityQueryState = getSimilarityQueryState();
   if(isValidSimilarityKey){
   
    similarityQueryState.similarity_key = paramSimilarityKey;
    similarityQueryState.sort_by = paramSortBy || similarityQueryState.sort_by;
    similarityQueryState.order_by = paramOrderBy || similarityQueryState.order_by;
    similarityQueryState.max_price = paramMaxPrice || similarityQueryState.max_price;

    updateSimilarityQueryState(similarityQueryState);
  }

  useEffect(() => {
    const fetchInitData = async () => {
      try {
        const response = await fetch(endpoints.init);
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const result = await response.json();

        appState.attribute_limits = result.attribute_limits;
        appState.filter_attributes = result.filter_attributes;
        appState.search_suggestions = result.search_suggestions || [];
        appState.candidate_keys = result.candidate_keys || [];
        appState.popular_evs = result.popular_evs;
        appState.precomputed_lists = result.precomputed_lists;

        updateAppState(appState);
      } catch (error) {
        console.error("Failed to fetch initialization data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchInitData();
  // eslint-disable-next-line
  }, []); // Run only once

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="app-container">
      <div className="main-content">
        <Routes>
          <Route
            path="/"
            element={<SelectionPanel searchTerm={searchQueryState.search_term} />}
          />
          <Route
            path="/similar-cars"
            element={<SimilarProducts productId={similarityQueryState.similarity_key} />}
          />
        </Routes>
  
        {location.pathname === "/" && (
          <div className="viewer-panel">
            <ViewerPanel />
          </div>
        )}
      </div>
  
      {/* Concise Yet Comprehensive Disclaimer */}
      <div className="disclaimer-section">
        <h2>Disclaimer</h2>
        <p>
          The information on <strong>pikeasy.com</strong> is for informational purposes only. While we strive for accuracy, 
          we do not guarantee that all EV specifications, pricing, incentives, and offers are up-to-date or error-free. 
          Users should verify details with manufacturers, dealerships, and official sources before making decisions.
        </p>
  
        <h3>Liability Limitation</h3>
        <p>
          <strong>pikeasy.com</strong> and its owners, employees, and partners are not liable for any errors, inaccuracies, 
          financial loss, or decisions made based on the Website's content. Use of this site is at your own risk.
        </p>
  
        <h3>Affiliate Disclosure</h3>
        <p>
          We participate in affiliate marketing programs, earning commissions when users click on links, submit lead forms, 
          or make purchases. These affiliations do not influence our reviews or rankings.
        </p>
  
        <h3>Third-Party Links</h3>
        <p>
          Our site may contain links to third-party websites. We do not control or take responsibility for the content, 
          policies, or accuracy of these external sites.
        </p>
  
        <h3>Contact</h3>
        <p>
          For inquiries, contact us at  
          <a href="mailto:user.support@pikeasy.com"> user.support@pikeasy.com</a>.
        </p>
  
        <p>© {new Date().getFullYear()} pikeasy.com | All Rights Reserved.</p>
      </div>
    </div>
  );
  
}

export default App;
