import { useEffect } from 'react';
import { getAppState, updateAppState, getSearchQueryState} from '../AppState';
import { ACTIONS } from '../Constants';
import { endpoints } from '../Config';

const DataFetch = () => {
  // Helper function to fetch data from the server
  const fetchData = async (url, data) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, requestOptions);
   
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
  };

  // Helper function to prepare request data based on action
  const getRequestData = (action, appState, searchQueryState) => {
    const commonData = { cached: Object.keys(appState.details) };
    switch (action) {
      case ACTIONS.LOOKUP:
        return { ...commonData, key: searchQueryState.search_term };
      default:
        return null;
    }
  };

  // Helper function to handle side effects after data fetching
  const handlePostFetchEffects = (result, appState) => {
    appState.details = { ...appState.details, ...result.diff_results };
    appState.results = result.result_keys;
    appState.refresh_results = true;
    updateAppState(appState);
  };

  // Function to handle app state change
  const handleAppStateChange = async () => {
    const appState = getAppState();
    const searchQueryState = getSearchQueryState();
    
    const action = appState.last_action;
    const requestData = getRequestData(action, appState, searchQueryState);

    if (!requestData) return;

    const actionEndpoints = {
      [ACTIONS.LOOKUP]: endpoints.lookup,
      [ACTIONS.SIMILAR]: endpoints.similar,
    };

    const url = actionEndpoints[action];

    try {
      appState.last_action = ACTIONS.INIT;
      updateAppState(appState);
      const result = await fetchData(url, requestData);
      handlePostFetchEffects(result, appState);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Set up effect to listen for app state changes
  useEffect(() => {
    window.addEventListener('appStateChange', handleAppStateChange);
    return () => {
      window.removeEventListener('appStateChange', handleAppStateChange);
    };
  });

};

export default DataFetch;
