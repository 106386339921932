export const ACTIONS = {
    INIT: 0,
    TRIMS:1,
    SIMILAR:2,
    LOOKUP: 3,
}

export const MODE = {
    SEARCH:0,
    SIMILARITY: 1
}