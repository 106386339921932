import { ACTIONS } from './Constants.js';

const AppState = {
  filter_attributes: null,
  attribute_limits: null,
  filter_selections: null,
  search_suggestions: null,
  candidate_keys: null,
  popular_evs: null,
  precomputed_lists: null,
  details: {},
  results: {},
  images: {},
  last_action: ACTIONS.INIT,
  refresh_results: false,
};

// Create a function to get the current state
function getAppState() {
  return { ...AppState }; // Return a copy to ensure immutability
}

// Create a function to update AppState and dispatch the event
function updateAppState(newState) {
  const previousAppState = { ...AppState };
  Object.assign(AppState, newState);

  const appStateChangeEvent = new CustomEvent('appStateChange', {
    detail: {
      previousAppState,
      currentAppState: AppState,
    },
  });
  window.dispatchEvent(appStateChangeEvent);
}

const SearchQueryState = {
  search_term: null, // Ensure search_term is part of the global state
  max_price: 0,
  sort_by: "price",
  order_by: "asc"
}

// Create a function to get the current state
function getSearchQueryState() {
  return { ...SearchQueryState }; // Return a copy to ensure immutability
}

// Create a function to update SearchQueryState and dispatch the event
function updateSearchQueryState(newState) {
  const previousState = { ...SearchQueryState };
 
 
  Object.assign(SearchQueryState, newState);
  console.log(newState);

  const searchQueryStateChangeEvent = new CustomEvent('searchQueryStateChange', {
    detail: {
      previousState,
      currentAppState: SearchQueryState,
    },
  });
  window.dispatchEvent(searchQueryStateChangeEvent);
}

const SimilarityQueryState = {
  similarity_key: "", // Ensure search_term is part of the global state
  max_price: 0,
  sort_by: "price",
  order_by: "asc"
}

// Create a function to get the current state
function getSimilarityQueryState() {
  return { ...SimilarityQueryState }; // Return a copy to ensure immutability
}

// Create a function to update AppState and dispatch the event
function updateSimilarityQueryState(newState) {
  const previousState = { ...SimilarityQueryState };
  Object.assign(SimilarityQueryState, newState);

  const similarityQueryStateChangeEvent = new CustomEvent('similarityQueryStateChange', {
    detail: {
      previousState,
      currentAppState: SimilarityQueryState,
    },
  });
  
  window.dispatchEvent(similarityQueryStateChangeEvent);
}

export { getAppState, updateAppState, getSearchQueryState, updateSearchQueryState, getSimilarityQueryState,  updateSimilarityQueryState};
