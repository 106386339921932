// Function to convert the new format to the expected format for ProductCard
export const convertResults = (key, specs, scores) => {
  // Helper function to check if a value is null or undefined
  const validValue = (value) => value !== null && value !== undefined ? value : "";

  return {
    key: key,
    title: `${specs.make} ${specs.model}`, // Combining make and model for title
    subtitle: validValue(specs.trim), // Using trim for subtitle
    attributes: {
      make: validValue(specs.make),
      model: validValue(specs.model),
      image: validValue(specs["image-url"]),
      trim: validValue(specs.trim),
      price: validValue(specs.price),
      federalIncentive: validValue(specs["federal-incentive"]),
      length: validValue(specs.length),
      width: validValue(specs.width),
      height: validValue(specs.height),
      seating: validValue(specs.seating),
      style: validValue(specs.style),
      driveTrain: validValue(specs["drive-train"]),
      cargoMin: validValue(specs["cargo-min"]),
      cargoMax: validValue(specs["cargo-max"]),
      groundClearance: validValue(specs["ground-clearance"]),
      cargoMinBags: validValue(specs["cargo-min-bags"]),
      cargoMaxBags: validValue(specs["cargo-max-bags"]),
      batteryMake: validValue(specs["battery-make"]),
      batteryChemistry: validValue(specs["battery-chemistry"]),
      size: validValue(specs.size),
      latestModelYear: validValue(specs["latest-model-year"]),
      maxSeating: validValue(specs["max-seating"]),
      range: validValue(specs.range),
      horsepower: validValue(specs.hp),
      torque: validValue(specs.torque),
      cargo: validValue(specs["cargo-max"]),
      batterySize: validValue(specs["battery-size"]),
      batteryWarranty: validValue(specs["battery-warranty"]),
      basicWarranty: validValue(specs["basic-warranty"]),
      powertrainWarranty: validValue(specs["powertrain-warranty"]),
      acOnboardCharger: validValue(specs["ac-onboard-charger"]),
      dcOnboardCharger: validValue(specs["dc-onboard-charger"]),
      kwh100Miles: validValue(specs["kwh-100-miles"]),
      dcChargeSpeed: validValue(specs["dc-charge-speed"]),
      acChargeSpeed: validValue(specs["ac-charge-speed"]),
      combinedMpge: validValue(specs["combined-mpge"]),
      allMpge: validValue(specs["all-mpge"]),

      // Handling missing offers gracefully
      leaseAmount: validValue(specs.offers?.lease?.["offer-payment"]),
      offerExpiry: validValue(specs.offers?.lease?.["offer-expiry"]),
      leaseTerm: validValue(specs.offers?.lease?.["lease-term"]),
      leaseDueAtSigning: validValue(specs.offers?.lease?.["due-signing"]),
      leaseOfferLink: validValue(specs.offers?.lease?.["offer-link"]),
      financeAmount: validValue(specs.offers?.apr?.["offer-payment"]),
      financeTerm: validValue(specs.offers?.apr?.["lease-term"]),
      financeOfferLink: validValue(specs.offers?.apr?.["offer-link"]),
      financeAPR: validValue(specs.offers?.apr?.["offer-payment"]),
      bonusOffer: validValue(specs.offers?.bonus?.["offer-payment"])
    },
    scores: {
      range: validValue(scores?.["score-range"]),
      hp: validValue(scores?.["score-hp"]),
      mpge: validValue(scores?.["score-mpge"]),
      fastCharge: validValue(scores?.["score-dc-charge-speed"]),
    },
  };
};
