import React, { useState, useEffect } from "react";
import ProductCard from "./ProductCard";
import { FaSort, FaArrowUp, FaArrowDown, FaTimes, FaSlidersH, FaLink } from "react-icons/fa"; 
import { getSearchQueryState, updateSearchQueryState, getSimilarityQueryState } from "../AppState"; 
import { MODE } from "../Constants"; 
import "./ProductGrid.css";

const sortingOptions = [
  { value: "price", label: "Price" },
  { value: "combinedMpge", label: "MPGe" },
  { value: "range", label: "Range" },
  { value: "size", label: "Size" },
  { value: "horsepower", label: "Horsepower" },
  { value: "maxSeating", label: "Max Seating" },
  { value: "dcChargeSpeed", label: "Fast Charging" },
];


const ProductGrid = ({ products, mode }) => {
  const searchQueryState = getSearchQueryState();
  const [sortCriteria, setSortCriteria] = useState(searchQueryState.sort_by);
  const [sortOrder, setSortOrder] = useState(searchQueryState.order_by);
  const [maxPrice, setMaxPrice] = useState(searchQueryState.max_price);
  const [sortedProducts, setSortedProducts] = useState(products);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [openSheet, setOpenSheet] = useState(null); // Controls which bottom sheet is open
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const [highestPrice, setHighestPrice] = useState(0);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (products.length !== 0) {
      const highestPriceValue = Math.max(...products.map(product => product.attributes.price || 0));
      setHighestPrice(highestPriceValue);

      if (mode === MODE.SEARCH) {
        const searchQueryState = getSearchQueryState();
        setSortCriteria(searchQueryState.sort_by);
        setSortOrder(searchQueryState.order_by);
        setMaxPrice(searchQueryState.max_price || highestPriceValue)  ;
      } else if (mode === MODE.SIMILARITY) {
        const similarityQueryState = getSimilarityQueryState();
        setSortCriteria(similarityQueryState.sort_by);
        setSortOrder(similarityQueryState.order_by);
        setMaxPrice(similarityQueryState.max_price || highestPriceValue);
      }
    }
  }, [products]);

  useEffect(() => {
    setSortedProducts(sortProducts(filterByMaxPrice(products))); 

    if (mode === MODE.SEARCH) {
      const searchQueryState = getSearchQueryState();
      searchQueryState.sort_by = sortCriteria;
      updateSearchQueryState(searchQueryState);
    }
    
    updateUrl();
  }, [products, sortCriteria])

  useEffect(() => {
    setSortedProducts(sortProducts(filterByMaxPrice(products))); 

    if (mode === MODE.SEARCH) {
      const searchQueryState = getSearchQueryState();
      searchQueryState.order_by = sortOrder;
      updateSearchQueryState(searchQueryState);
    }
    
    updateUrl();
  }, [products, sortOrder])

  useEffect(() => {
    setSortedProducts(sortProducts(filterByMaxPrice(products))); 

    if (mode === MODE.SEARCH) {
      const searchQueryState = getSearchQueryState();
      searchQueryState.max_price = maxPrice;
      updateSearchQueryState(searchQueryState);
    }
    
    updateUrl();
  }, [products, maxPrice])


  const sortProducts = (products) => {
    return [...products].sort((a, b) => {
      let valueA = a.attributes[sortCriteria];
      let valueB = b.attributes[sortCriteria];
  
      // Convert string values to numbers if possible
      valueA = typeof valueA === "string" ? parseFloat(valueA.replace(/[^0-9.]/g, "")) || 0 : valueA;
      valueB = typeof valueB === "string" ? parseFloat(valueB.replace(/[^0-9.]/g, "")) || 0 : valueB;
  
      // Ensure both are numbers before sorting
      if (typeof valueA === "number" && typeof valueB === "number") {
        return sortOrder === "asc" ? valueA - valueB : valueB - valueA;
      }
  
      return 0; // Default case: if values are not numbers, do not change order
    });
  };
  
  const updateUrl = () => {
    const params = new URLSearchParams();

    if (mode === MODE.SEARCH) {
      const searchQueryState = getSearchQueryState();
      params.append("search_term", searchQueryState.search_term || "");
    } else if (mode === MODE.SIMILARITY) {
      const similarityQueryState = getSimilarityQueryState();
      params.append("key", similarityQueryState.similarity_key || "");
    }

    params.append("sort_by", sortCriteria || "price");
    params.append("order_by", sortOrder || "asc");
    params.append("max_price", maxPrice || 0);

    const newUrl = `${window.location.pathname}?${params.toString()}`;
    window.history.replaceState(null, "", newUrl);
  };

  const filterByMaxPrice = (products) => {
    return products.filter((product) => {
      const price = product.attributes.price;
      return price <= maxPrice;
    });
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(window.location.href).then(() => {
      setIsLinkCopied(true);
      setTimeout(() => setIsLinkCopied(false), 2000);
    });
  };

  const toggleBottomSheet = (sheet) => {
    setOpenSheet(openSheet === sheet ? null : sheet);
  };

  return (
    <div className="product-grid-container">
      <div className="sort-controls">
      {!isMobile ? (
          <>
            <FaSort className="sort-dropdown-icon" />
            <select className="dropdown-select" value={sortCriteria} onChange={(e) => setSortCriteria(e.target.value)}>
            {sortingOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label} 
              </option>
            ))}
            </select>
            <button className="sort-toggle-btn" onClick={() => setSortOrder(sortOrder === "asc" ? "desc" : "asc")}>
              {sortOrder === "asc" ? <FaArrowUp className="arrow-icon" /> : <FaArrowDown className="arrow-icon" />}
            </button>
            <div className="max-price-control">
              <label className="max-price-label">Max Price</label>
              <input type="range" className="max-price-slider" min="0" max={highestPrice} step="500" value={maxPrice} onChange={(e) => setMaxPrice(e.target.value)} />
              <input type="number" className="max-price-input" step="500" value={maxPrice} onChange={(e) => setMaxPrice(e.target.value)} />
            </div>
          </>
        ) : (
          <>
            <button className="sort-icon-btn" onClick={() => toggleBottomSheet("sort")}>
              <FaSort className="sort-icon" /> 
              {sortingOptions.find(option => option.value === sortCriteria)?.label || "Sort"}
            </button>

            <button className="sort-toggle-btn" onClick={() => setSortOrder(sortOrder === "asc" ? "desc" : "asc")}>
              {sortOrder === "asc" ? <FaArrowUp className="arrow-icon" /> : <FaArrowDown className="arrow-icon" />}
            </button>
            <button className="sort-icon-btn" onClick={() => toggleBottomSheet("price")}>
              <FaSlidersH /> Max Price
            </button>
          </>
        )}
      </div>

      {openSheet && (
        <div className="bottom-sheet-overlay" onClick={() => toggleBottomSheet(null)}>
          <div className="bottom-sheet" onClick={(e) => e.stopPropagation()}>
            <button className="close-btn" onClick={() => toggleBottomSheet(null)}>
              <FaTimes />
            </button>
            {openSheet === "sort" && (
              <>
                <h3>Select Sorting Criteria</h3>
                <ul>
                {sortingOptions.map((option) => (
                  <li 
                    key={option.value} 
                    onClick={() => { setSortCriteria(option.value); toggleBottomSheet(null); }} 
                    className={sortCriteria === option.value ? "selected" : ""}
                  >
                    {option.label} {/* ✅ Directly using the label, no need for charAt() */}
                  </li>
                ))}
              </ul>
              </>
            )}
            {openSheet === "price" && (
              <>
                <label className="max-price-label">Max Price</label>
                <input type="range" className="max-price-slider" min="0" max={highestPrice} step="500" value={maxPrice}  onChange={(e) => setMaxPrice(e.target.value)} />
                <input type="number" className="max-price-input" step="500" value={maxPrice} onChange={(e) => setMaxPrice(e.target.value)} />
              </>
            )}
          </div>
        </div>
      )}

    <div className="results-info">
            {sortedProducts.length}/{products.length} results shown based on Max Price filter.
            {sortedProducts.length > 0 && (
              <span> Max Price in List: ${Math.max(...products.map(product => product.attributes.price || 0)).toLocaleString()}</span>
            )}
            <button
              className={`copy-link-btn ${isLinkCopied ? "copied" : ""}`}
              onClick={handleCopyLink}
            >
              <FaLink className="copy-icon" />
              {isLinkCopied ? " Link copied!" : " Copy page link"}
            </button>
            
          </div>
          {/* Disclaimer */}
          <div className="disclaimer">
            <sup>†</sup>Lease and finance offers vary by location and dealer participation. Terms and conditions apply. Contact your local dealer for availability and details.
        </div>


      <div className="product-grid">
        {sortedProducts.map((product, index) => (
          <ProductCard key={product.key} product={product} productNumber={index + 1} totalProducts={sortedProducts.length} />
        ))}
      </div>
    </div>
  );
};

export default ProductGrid;
