import React, { useState, useEffect } from "react";
import "./ViewerPanel.css";
import ProductGrid from "./ProductGrid";
import { convertResults } from "./Util";
import { updateAppState} from "../AppState";
import { MODE } from "../Constants";

export const ViewerPanel = ({}) => {
  const [results, setResults] = useState([]);

  useEffect(() => {
    // Define a function to handle changes to AppState
    const handleAppStateChange = (event) => {
      const { currentAppState } = event.detail;
      if (currentAppState.refresh_results === true) {
        const results = [];
        for (const res of currentAppState.results) {
          results.push(
            convertResults(
              res,
              currentAppState.details[res].specs,
              currentAppState.details[res].scores
            )
          );
        }
        setResults(results);
        currentAppState.refresh_results = false;
        updateAppState(currentAppState);
      }
    };

    // Attach the listener to AppState changes
    window.addEventListener("appStateChange", handleAppStateChange);

    // Clean up the listener when the component unmounts
    return () => {
      window.removeEventListener("appStateChange", handleAppStateChange);
    };
  }, []);

  return (
    <div className="viewer-panel">
      <ProductGrid
        products={results}
        mode = {MODE.SEARCH}
      />
    </div>
  );
};

export default ViewerPanel;