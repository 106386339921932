const url = "https://pikeasy-ui-server-821395abadd6.herokuapp.com/";
//const url = "http://localhost:3001/";

export const endpoints = {
    init: url,
    trims: url + "trims",
    similar: url + "similar",
    lookup: url + "lookup",
};

