import React from 'react';
import './SpecCard.css';

const SpecCard = ({ specs }) => {
  // Define sections with titles and associated data keys
  const sections = [
    {
      title: "Basic Info",
      data: [
        { label: "Make", key: "make" },
        { label: "Model", key: "model" },
        { label: "Style", key: "style" },
        { label: "Drive Train", key: "driveTrain" }, // Updated key
        { label: "Price", key: "price", format: (val) => `$${val.toLocaleString()}` },
        { label: "Federal Incentive", key: "federalIncentive", format: (val) => `$${val}` },
      ],
    },
    {
      title: "Dimensions",
      data: [
        { label: "Cargo Capacity", key: "cargoMin", format: (val) => `${val} - ${specs["cargoMax"]} cu.ft` }, // Updated keys
        { label: "Ground Clearance", key: "groundClearance", format: (val) => `${val} inches` },
        { label: "Length", key: "length", format: (val) => `${val} inches` },
        { label: "Width", key: "width", format: (val) => `${val} inches` },
        { label: "Height", key: "height", format: (val) => `${val} inches` },
      ],
    },
    {
      title: "Battery",
      data: [
        { label: "Battery Make", key: "batteryMake" }, // Updated key
        { label: "Battery Size", key: "batterySize", format: (val) => `${val} kWh` }, // Updated key
        { label: "AC Onboard Charger", key: "acOnboardCharger", format: (val) => `${val} kW` }, // Updated key
        { label: "DC Onboard Charger", key: "dcOnboardCharger", format: (val) => `${val} kW` }, // Updated key
        { label: "Home Charge Speed", key: "acChargeSpeed", format: (val) => `${val} mi/h` }, // Updated key
        { label: "Fast Charge Speed", key: "dcChargeSpeed", format: (val) => `${val} mi/h` }, // Updated key
      ],
    },
    {
      title: "Performance",
      data: [
        { label: "Range", key: "range", format: (val) => `${val} miles` },
        { label: "MPGe (Cty/Hwy/Com)", key: "allMpge" }, // Updated key
        { label: "Horsepower", key: "horsepower", format: (val) => `${val} hp` }, // Updated key
        { label: "Torque", key: "torque", format: (val) => `${val} lb-ft` },
      ],
    },
    {
      title: "Warranty",
      data: [
        { label: "Battery Warranty", key: "batteryWarranty" }, // Updated key
        { label: "Basic Warranty", key: "basicWarranty" }, // Updated key
        { label: "Powertrain Warranty", key: "powertrainWarranty" }, // Updated key
      ],
    },
  ];

  return (
    <div className="spec-card">
      <table className="spec-table">
        {sections.map((section) => (
          <React.Fragment key={section.title}>
            <thead>
              <tr>
                <th colSpan="2">{section.title}</th>
              </tr>
            </thead>
            <tbody>
              {section.data.map((item) => (
                <tr key={item.key}>
                  <td>{item.label}</td>
                  <td>
                    { 
                       item.format
                       ? item.format(specs[item.key] ?? "N/A")
                       : (specs[item.key] ?? "N/A")
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </React.Fragment>
        ))}
      </table>
    </div>
  );
};

export default SpecCard;
